// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/sstephenson/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .

/* decrypt helper function */
function decryptCharcode(n, start, end, offset) {
  n = n + offset;
  if (offset > 0 && n > end) {
    n = start + (n - end - 1);
  } else if (offset < 0 && n < start) {
    n = end - (start - n - 1);
  }
  return String.fromCharCode(n);
}

/* decrypt string */
function decryptString(enc, offset) {
  var dec = "";
  var len = enc.length;
  for (var i = 0; i < len; i++) {
    var n = enc.charCodeAt(i);
    if (n >= 0x2b && n <= 0x3a) {
      dec += decryptCharcode(n, 0x2b, 0x3a, offset); /* 0-9 . , - + / : */
    } else if (n >= 0x40 && n <= 0x5a) {
      dec += decryptCharcode(n, 0x40, 0x5a, offset); /* A-Z @ */
    } else if (n >= 0x61 && n <= 0x7a) {
      dec += decryptCharcode(n, 0x61, 0x7a, offset); /* a-z */
    } else {
      dec += enc.charAt(i);
    }
  }
  return dec;
}

/* decrypt spam-protected emails */
function linkTo_UnCryptMailto(s) {
  location.href = decryptString(s, -2);
}

window.linkTo_UnCryptMailto = linkTo_UnCryptMailto;
